import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        name: 'Root',
        meta: {
            hidden: true
        }
    },
    {
        path: '/redirect',
        component: Layout,
        name: 'Redirect',
        children: [
            {
                path: '/redirect/:path(.*)',
                name: 'Redirect',
                component: () => import('@/views/Redirect/Redirect.vue'),
                meta: {},
            },
        ],
        meta: {
            hidden: true,
        },
    },
    {
        path: '/login',
        component: () => import('@/views/Login/Login.vue'),
        name: 'Login',
        meta: {
            hidden: true,
            title: t('router.login'),
        },
    },
    {
        path: '/dashboard',
        component: Layout,
        name: '_Dashboard',
        meta: {},
        children: [
            {
                path: '',
                component: () => import('@/views/Dashboard/Index.vue'),
                name: 'Dashboard',
                meta: {
                    title: t('router.dashboard'),
                    icon: 'vi-ant-design:dashboard-twotone',
                    noCache: true,
                },
            },
        ],
    },
    {
        path: '/profile',
        component: Layout,
        name: '_Profile',
        meta: {
            title: t('router.userProfile'),
            hidden: true,
        },
        children: [
            {
                path: '',
                component: () => import('@/views/Profile/Index.vue'),
                name: 'Profile',
                meta: {
                    title: t('router.userProfile'),
                    hidden: true,
                },
            },
            {
                path: 'change-password',
                component: () => import('@/views/Profile/ChangePassword.vue'),
                name: 'ChangePassword',
                meta: {
                    title: t('router.changePassword'),
                    hidden: true,
                    noCache: true,
                },
            },
        ],
    },
    {
        path: '/404',
        component: () => import('@/views/Error/404.vue'),
        name: 'NotFound',
        meta: {
            hidden: true,
            title: 'Page not found',
        },
    },
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
    {
        path: '/properties',
        component: Layout,
        name: '_Properties',
        meta: {
            title: t('router.properties'),
            icon: 'vi-f7:building-2',
        },
        children: [

            {
                path: '/property-owners',
                component: () => import('@/views/Owners/Index.vue'),
                name: 'PropertyOwners',
                meta: {
                    title: t('router.owners'),
                },
            },
            {
                path: '/property-owners/:id(\\d+)',
                component: () => import('@/views/Owners/Show.vue'),
                name: 'PropertyOwnersShow',
                meta: {
                    title: t('owners.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/property-owners',
                },
            },
            {
                path: '/property-owners/:id(\\d+)/edit',
                component: () => import('@/views/Owners/Edit.vue'),
                name: 'PropertyOwnersEdit',
                meta: {
                    title: t('owners.edit'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/property-owners',
                },
            },
            {
                path: '/property-owners/create',
                component: () => import('@/views/Owners/Edit.vue'),
                name: 'PropertyOwnersCreate',
                meta: {
                    title: t('owners.create'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/property-owners',
                },
            },
            {
                path: '',
                component: () => import('@/views/Properties/Index.vue'),
                name: 'Properties',
                meta: {
                    title: t('properties.listing'),
                    submenu_title: t('router.myListings'), //shows for submenu, when it's not the only child menu item
                    icon: 'vi-f7:building-2',
                },
            },
            {
                path: ':id(\\d+)',
                component: () => import('@/views/Properties/Show.vue'),
                name: 'PropertiesShow',
                meta: {
                    title: t('properties.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/properties',
                },
            },
            {
                path: ':id(\\d+)/edit',
                component: () => import('@/views/Properties/Edit.vue'),
                name: 'PropertiesEdit',
                meta: {
                    title: t('properties.edit'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/properties',
                },
            },
            {
                path: 'create',
                component: () => import('@/views/Properties/Edit.vue'),
                name: 'PropertiesCreate',
                meta: {
                    title: t('properties.create'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/properties',
                },
            },
            {
                path: 'sublist',
                component: () => import('@/views/Properties/Sublist.vue'),
                name: 'PropertiesSublist',
                meta: {
                    title: t('router.subListings'),
                },
            },
            {
                path: 'sub/:id(\\d+)',
                component: () => import('@/views/Properties/SublistShow.vue'),
                name: 'PropertiesSublistShow',
                meta: {
                    title: t('properties.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/properties/sublist',
                },
            },
        ],
    },
    {
        path: '/contacts',
        component: Layout,
        name: '_Contacts',
        meta: {
            title: t('router.contacts'),
            icon: 'vi-teenyicons:contact-outline',
        },
        children: [
            {
                path: '',
                component: () => import('@/views/Contacts/Index.vue'),
                name: 'Contacts',
                meta: {
                    title: t('router.contacts'),
                    submenu_title: t('router.myListings'),
                    icon: 'vi-teenyicons:contact-outline',
                },
            },
            {
                path: 'sublist',
                component: () => import('@/views/Contacts/Sublist.vue'),
                name: 'ContactsSublist',
                meta: {
                    title: t('router.subListings'),
                },
            },
            {
                path: ':id(\\d+)',
                component: () => import('@/views/Contacts/Show.vue'),
                name: 'ContactsShow',
                meta: {
                    title: t('contacts.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/contacts',
                },
            },
            {
                path: 'sub/:id(\\d+)',
                component: () => import('@/views/Contacts/SublistShow.vue'),
                name: 'ContactsSublistShow',
                meta: {
                    title: t('contacts.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/contacts/sublist',
                },
            },
            {
                path: ':id(\\d+)/edit',
                component: () => import('@/views/Contacts/Edit.vue'),
                name: 'ContactsEdit',
                meta: {
                    title: t('contacts.edit'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/contacts',
                },
            },
            {
                path: 'create',
                component: () => import('@/views/Contacts/Edit.vue'),
                name: 'ContactsCreate',
                meta: {
                    title: t('contacts.create'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/contacts',
                },
            },
        ],
    },
    {
        path: '/leads',
        component: Layout,
        name: '_Leads',
        meta: {
            title: t('router.leads'),
            icon: 'vi-mdi:leads-outline',
        },
        children: [
            {
                path: '',
                component: () => import('@/views/Leads/Index.vue'),
                name: 'Leads',
                meta: {
                    title: t('router.leads'),
                    submenu_title: t('router.myListings'),
                    icon: 'vi-mdi:leads-outline',
                },
            },
            {
                path: 'sublist',
                component: () => import('@/views/Leads/Sublist.vue'),
                name: 'LeadsSublist',
                meta: {
                    title: t('router.subListings'),
                },
            },
            {
                path: ':id(\\d+)',
                component: () => import('@/views/Leads/Show.vue'),
                name: 'LeadsShow',
                meta: {
                    title: t('leads.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/leads',
                },
            },
            {
                path: 'sub/:id(\\d+)',
                component: () => import('@/views/Leads/SublistShow.vue'),
                name: 'LeadsSublistShow',
                meta: {
                    title: t('leads.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/leads/sublist',
                },
            },
            {
                path: ':id(\\d+)/edit',
                component: () => import('@/views/Leads/Edit.vue'),
                name: 'LeadsEdit',
                meta: {
                    title: t('leads.edit'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/leads',
                },
            },
            {
                path: 'create',
                component: () => import('@/views/Leads/Edit.vue'),
                name: 'LeadsCreate',
                meta: {
                    title: t('leads.create'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/leads',
                },
            },
        ],
    },
    {
        path: '/opportunities',
        component: Layout,
        name: '_Opportunities',
        meta: {
            title: t('router.opportunities'),
            icon: 'vi-material-symbols:map-outline',
        },
        children: [
            {
                path: '',
                component: () => import('@/views/Opportunities/Index.vue'),
                name: 'Opportunities',
                meta: {
                    title: t('router.opportunities'),
                    submenu_title: t('router.myListings'),
                    icon: 'vi-material-symbols:map-outline',
                },
            },
            {
                path: 'sublist',
                component: () => import('@/views/Opportunities/Sublist.vue'),
                name: 'OpportunitiesSublist',
                meta: {
                    title: t('router.subListings'),
                },
            },
            {
                path: ':id(\\d+)',
                component: () => import('@/views/Opportunities/Show.vue'),
                name: 'OpportunitiesShow',
                meta: {
                    title: t('opportunities.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/opportunities',
                },
            },
            {
                path: 'sub/:id(\\d+)',
                component: () => import('@/views/Opportunities/SublistShow.vue'),
                name: 'OpportunitiesSublistShow',
                meta: {
                    title: t('opportunities.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/opportunities/sublist',
                },
            },
            {
                path: ':id(\\d+)/edit',
                component: () => import('@/views/Opportunities/Edit.vue'),
                name: 'OpportunitiesEdit',
                meta: {
                    title: t('opportunities.edit'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/opportunities',
                },
            },
            {
                path: 'create',
                component: () => import('@/views/Opportunities/Edit.vue'),
                name: 'OpportunitiesCreate',
                meta: {
                    title: t('opportunities.create'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/opportunities',
                },
            },
        ],
    },
    {
        path: '/deals',
        component: Layout,
        name: '_Deals',
        meta: {
            title: t('router.deals'),
            icon: 'vi-ant-design:dollar-outlined',
        },
        children: [
            {
                path: '',
                component: () => import('@/views/Deals/Index.vue'),
                name: 'Deals',
                meta: {
                    title: t('router.deals'),
                    submenu_title: t('router.myListings'),
                    icon: 'vi-ant-design:dollar-outlined',
                },
            },
            {
                path: 'sublist',
                component: () => import('@/views/Deals/Sublist.vue'),
                name: 'DealsSublist',
                meta: {
                    title: t('router.subListings'),
                },
            },
            {
                path: ':id(\\d+)',
                component: () => import('@/views/Deals/Show.vue'),
                name: 'DealsShow',
                meta: {
                    title: t('deals.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/deals',
                },
            },
            {
                path: 'sub/:id(\\d+)',
                component: () => import('@/views/Deals/SublistShow.vue'),
                name: 'DealsSublistShow',
                meta: {
                    title: t('deals.details'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/deals/sublist',
                },
            },
            {
                path: ':id(\\d+)/edit',
                component: () => import('@/views/Deals/Edit.vue'),
                name: 'DealsEdit',
                meta: {
                    title: t('deals.edit'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/deals',
                },
            },
            {
                path: 'create',
                component: () => import('@/views/Deals/Edit.vue'),
                name: 'DealsCreate',
                meta: {
                    title: t('deals.create'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/deals',
                },
            },
        ],
    },
    {
        path: '/users',
        component: Layout,
        name: '_Users',
        meta: {
            title: t('router.users'),
            icon: 'vi-ph:users',
        },
        children: [
            {
                path: '',
                component: () => import('@/views/Users/Index.vue'),
                name: 'Users',
                meta: {
                    title: t('router.users'),
                    icon: 'vi-ph:users',
                },
            },
            {
                path: ':id(\\d+)/edit',
                component: () => import('@/views/Users/Edit.vue'),
                name: 'UsersEdit',
                meta: {
                    title: t('users.edit'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/users',
                },
            },
            {
                path: 'create',
                component: () => import('@/views/Users/Edit.vue'),
                name: 'UsersCreate',
                meta: {
                    title: t('users.create'),
                    noCache: true,
                    hidden: true,
                    activeMenu: '/users',
                },
            },
        ],
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    strict: true,
    routes: constantRouterMap as RouteRecordRaw[],
    scrollBehavior: () => ({ left: 0, top: 0 }),
})

export const resetRouter = (): void => {
    router.getRoutes().forEach((route) => {
        const { name } = route
        if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
            router.hasRoute(name) && router.removeRoute(name)
        }
    })
}

export const setupRouter = (app: App<Element>) => {
    app.use(router)
}

export default router
